import { observer } from "mobx-react";
import { DialogFooter, Label, PrimaryButton } from "office-ui-fabric-react";
import * as React from "react";
import { AbstractDatenImportModel, BaseFile } from "../../generated/BackendClient";
import { allMetadata } from "../../generated/RadRepository";
import { IEntityRepositoryWithFile } from "../../Rad/DAL/EntityRepository";
import { nameof, Utils } from "../../Utils/Utils";
import { DBDialog, EasyForm, FolderView, ObserverSpan } from "../Generic";
import { CatchReactErrors } from "../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class DatenImportViewDialog<T extends AbstractDatenImportModel, F extends BaseFile> extends React.Component<
    {
        Repository: IEntityRepositoryWithFile<T, F>;
        InitialItem: T;
        CloseDialog: () => void;
    },
    {}
> {
    public readonly state = {};

    public render(): JSX.Element {
        return (
            <DBDialog
                onDismiss={() => {
                    this.ExitDialog();
                }}
                dialogContentProps={{
                    title: "Datenimport"
                }}
                minWidth="760px"
            >
                <EasyForm Object={this.props.InitialItem} Disabled={true}>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm3">
                                <Label>Status</Label>
                                <ObserverSpan
                                    PropertyName={nameof<AbstractDatenImportModel>("k0F61DatenImportStatus")}
                                    FormatFunction={(s) =>
                                        Utils.ApplyMapping(
                                            s,
                                            allMetadata.enumMapping["DB.LSK.Model.Enums+EnumDatenImportStatus"]
                                        )
                                    }
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm4">
                                <Label>{"\u2211 Zeilen"}</Label>
                                <ObserverSpan
                                    PropertyName={nameof<AbstractDatenImportModel>("k0F62DatenImportItemsToImport")}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm5">
                                <Label>Zeilen verarbeitet</Label>
                                <ObserverSpan
                                    PropertyName={nameof<AbstractDatenImportModel>("k0F63DatenImportItemsImported")}
                                />
                            </div>
                        </div>
                        {this.props.InitialItem.k0F60DatenImportInfo && (
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12">
                                    <Label>Datenimport Übersicht</Label>
                                    <ObserverSpan
                                        PropertyName={nameof<AbstractDatenImportModel>("k0F60DatenImportInfo")}
                                    />
                                </div>
                            </div>
                        )}
                        <br />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12">
                                <FolderView
                                    Repository={this.props.Repository}
                                    ParentObjectId={this.props.InitialItem.objectId}
                                    Disabled={true}
                                    Label="Importierte Datei"
                                    Compact={false}
                                />
                            </div>
                        </div>
                    </div>
                    <DialogFooter>
                        <PrimaryButton
                            onClick={() => {
                                this.ExitDialog();
                            }}
                            text="Schließen"
                        />
                    </DialogFooter>
                </EasyForm>
            </DBDialog>
        );
    }

    public componentDidMount() {}

    private async ExitDialog() {
        this.props.CloseDialog();
    }
}
