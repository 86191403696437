import { CommandBar, IStackTokens, Spinner, Stack } from "office-ui-fabric-react";
import { Image } from "office-ui-fabric-react/lib/Image";
import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { LskRepository } from "../DAL/LskRepository";
import { RadRepository } from "../generated/RadRepository";
import { BerichteRepository } from "../Model/Berichte";
import { Knz2Repository as Knz2GK2018Repository } from "../Model/Knz2/GK2018/Knz2Repository";
import { Knz2Repository as Knz2GK2022Repository } from "../Model/Knz2/Knz2Repository";
import { Knz3Repository } from "../Model/Knz3";
import { Knz4Repository } from "../Model/Knz4LS";
import { Knz4LVRepository } from "../Model/Knz4LV";
import { WorkflowRepository } from "../Model/Workflows/WorkflowRepository";
import {
    EnumKennzahl,
    EnumUserRole,
    Knz4LSClient,
    Knz4LVClient,
    UserContextModelOfEnumUserRole,
    UserRepository
} from "../Rad/DAL";
import { ManageUsers } from "../Rad/tsx/ManageUsers/ManageUsers";
import { RadListContainer } from "../Rad/tsx/RadList/RadListContainer";
import RadNav, { IRadNavLinkGroup } from "../Rad/tsx/RadNav";
import "../Style/style.scss";
import { RoleOverview } from "./Admin/RoleOverview";
import { BerichteContainer } from "./Berichte/BerichteContainer";
import { DatenImportContainer } from "./Datenimport";
import { DBFabric, FolderView, Header } from "./Generic";
import { Health } from "./Health";
import { Knz2AltprojekteContainer } from "./Knz2/Altprojekte";
import { Knz2Container as Knz2GK2022Container } from "./Knz2/Bereiche";
import { Knz2LSProjekteContainer as Knz2LSGK2022ProjekteContainer } from "./Knz2/Projekte/Knz2LSProjekteContainer";
import { Knz2Container as Knz2GK2018Container } from "./Knz2GK2018/Bereiche";
import { Knz2LSProjekteContainer as Knz2LSGK2018ProjekteContainer } from "./Knz2GK2018/Projekte";
import { Knz3DatenImportContainer } from "./Knz3/DatenImport";
import { Knz4LSContainer } from "./Knz4LS/Knz4LSContainer";
import { Knz4LSSettings } from "./Knz4LS/Knz4LSSettings";
import { Knz4LVContainer } from "./Knz4LV";
import { Welcome } from "./Welcome";
import { TaskListContainer } from "./Workflow";

const defaultUsers = [
    EnumUserRole.FachlicherAdmin,
    EnumUserRole.Reader,
    EnumUserRole.DBAGUmwelt,
    EnumUserRole.DBLeitungLS,
    EnumUserRole.DBNetzUmweltschutz
];

const groups: IRadNavLinkGroup = {
    links: [
        { name: "Start", url: "/" },
        {
            name: "Kennzahl 1",
            requiresEntityAccess: ["BerichtModel"],
            requiresRole: [...defaultUsers, EnumUserRole.BerichtKnz1],
            url: null,
            isExpanded: true,
            links: [
                {
                    name: "Berichte",
                    url: "/Knz1Berichte"
                }
            ]
        },
        {
            name: "Kennzahl 2",
            url: null,
            isExpanded: true,
            links: [
                {
                    name: "Bereiche",
                    url: "/Knz2Bereiche",
                    requiresEntityAccess: ["Knz2GK2022BereichModel"]
                },
                {
                    name: "Projekte",
                    url: "/Knz2Projekte",
                    requiresEntityAccess: ["Knz2GK2022ProjektModel"]
                },
                {
                    name: "Altprojekte",
                    url: "/Knz2Altprojekte",
                    requiresEntityAccess: ["Knz2AltprojektModel"]
                },
                {
                    name: "Berichte LS",
                    url: "/Knz2Berichte",
                    requiresEntityAccess: ["BerichtModel"],
                    requiresRole: [...defaultUsers, EnumUserRole.BerichtKnz2]
                },
                {
                    name: "Berichte LV",
                    url: "/Knz2LVBerichte",
                    requiresEntityAccess: ["BerichtModel"],
                    requiresRole: [...defaultUsers, EnumUserRole.BerichtKnz2]
                },
                {
                    name: "GK2018",
                    url: null,
                    isExpanded: true,
                    links: [
                        {
                            name: "Bereiche",
                            url: "/Knz2BereicheGK2018",
                            requiresEntityAccess: ["Knz2GK2018BereichModel"]
                        },
                        {
                            name: "Projekte",
                            url: "/Knz2ProjekteGK2018",
                            requiresEntityAccess: ["Knz2GK2018ProjektModel"]
                        }
                    ]
                }
            ]
        },
        {
            name: "Kennzahl 3",
            url: null,
            isExpanded: true,
            links: [
                {
                    name: "Datenimporte",
                    url: "/Knz3Datenimporte",
                    requiresEntityAccess: ["Knz3DatenImportModel"]
                },
                {
                    name: "Berichte LS",
                    url: "/Knz3LSBerichte",
                    requiresEntityAccess: ["BerichtModel"],
                    requiresRole: [...defaultUsers, EnumUserRole.BerichtKnz3LS]
                },
                {
                    name: "Berichte LV",
                    url: "/Knz3LVBerichte",
                    requiresEntityAccess: ["BerichtModel"],
                    requiresRole: [...defaultUsers, EnumUserRole.BerichtKnz3LV]
                },
                {
                    name: "Berichte SP",
                    url: "/Knz3SPBerichte",
                    requiresEntityAccess: ["BerichtModel"],
                    requiresRole: [...defaultUsers, EnumUserRole.BerichtKnz3LS]
                },
                {
                    name: "Erlaubte Abkürzungen",
                    url: "/Knz3ErlaubteAbk",
                    requiresEntityAccess: ["Knz3AbkModel"]
                }
            ]
        },
        {
            name: "Kennzahl 4 LS",
            url: null,
            isExpanded: true,
            links: [
                {
                    name: "Objekte",
                    url: "/Knz4LS",
                    requiresEntityAccess: ["Knz4LSModel"]
                },
                {
                    name: "Firmen",
                    url: "/Knz4LSFirmen",
                    requiresEntityAccess: ["Knz4LSIngBueroModel"]
                },
                {
                    name: "Absender",
                    url: "/Knz4LSAbsender",
                    requiresEntityAccess: ["Knz4LSAbsenderModel"]
                },
                {
                    name: "Fußzeile",
                    url: "/Knz4LSFusszeile",
                    requiresEntityAccess: ["Knz4LSSettingModel"]
                },
                {
                    name: "Datenimporte",
                    url: "/Knz4LSDatenimporte",
                    requiresEntityAccess: ["Knz4LSDatenImportModel"]
                },
                {
                    name: "Berichte",
                    url: "/Knz4LSBerichte",
                    requiresEntityAccess: ["BerichtModel"],
                    requiresRole: [...defaultUsers, EnumUserRole.BerichtKnz4LS]
                }
            ]
        },
        {
            name: "Kennzahl 4 LV",
            url: null,
            isExpanded: true,
            links: [
                {
                    name: "Maßnahmen",
                    url: "/Knz4LV",
                    requiresEntityAccess: ["Knz4LVModel"]
                },
                {
                    name: "Datenimporte",
                    url: "/Knz4LVDatenimporte",
                    requiresEntityAccess: ["Knz4LVDatenImportModel"]
                },
                {
                    name: "Berichte",
                    url: "/Knz4LVBerichte",
                    requiresEntityAccess: ["BerichtModel"],
                    requiresRole: [...defaultUsers, EnumUserRole.BerichtKnz4LV]
                }
            ]
        },
        {
            name: "Aufgaben",
            url: "/Tasks",
            isExpanded: true
            //   badge: "99"
        },
        {
            name: "GPÜ",
            url: "/Report",
            isExpanded: true,
            requiresRole: [
                ...defaultUsers,
                EnumUserRole.BerichtKnz1,
                EnumUserRole.Knz2Benutzer,
                EnumUserRole.BerichtKnz3LS,
                EnumUserRole.Knz4LSBenutzer
            ]
        },
        {
            name: "Administration",
            url: null,
            isExpanded: true,
            links: [
                {
                    name: "Benutzer",
                    url: "/_admin/ManageUsers",
                    requiresRole: [EnumUserRole.FachlicherAdmin]
                },
                {
                    name: "Rollen",
                    url: "/_admin/Role",
                    requiresRole: [EnumUserRole.FachlicherAdmin]
                },
                {
                    name: "Health",
                    url: "/_admin/Health",
                    requiresAdmin: true
                }
            ]
        }
    ]
};

const stackTokens: IStackTokens = { childrenGap: 15 };

export function App() {
    const [currentUser, setCurrentUser] = React.useState<UserContextModelOfEnumUserRole>(null);
    (async () => {
        let profile = await UserRepository.GetCurrentUser();
        setCurrentUser(profile);
    })();

    if (!currentUser) {
        return (
            <DBFabric>
                <div style={{ height: "100%" }}>
                    <Stack horizontalAlign="center" verticalAlign="center" verticalFill>
                        <Stack.Item>
                            <Welcome />
                        </Stack.Item>
                        <Stack.Item>
                            <Spinner
                                label="Einen Moment bitte..."
                                style={{ verticalAlign: "middle", height: "100%", marginTop: "100px" }}
                            />
                        </Stack.Item>
                    </Stack>
                </div>
            </DBFabric>
        );
    }
    return (
        <DBFabric>
            <BrowserRouter>
                <Stack horizontalAlign="start" verticalAlign="start" verticalFill horizontal tokens={stackTokens}>
                    <Stack.Item>
                        <Stack>
                            <Stack.Item align="center">
                                <div style={{ position: "relative" }}>
                                    <Image src="/db_logo.svg" width="150px" height="150px" />
                                    {currentUser.logoBanner && (
                                        <div
                                            style={{
                                                position: "absolute",
                                                bottom: "8px",
                                                right: "0px",
                                                backgroundColor: "#0000FF",
                                                fontSize: "60px",
                                                color: "#ffffff",
                                                fontWeight: "bold",
                                                padding: "8px 16px 8px 16px"
                                            }}
                                        >
                                            {currentUser.logoBanner}
                                        </div>
                                    )}
                                </div>
                            </Stack.Item>
                            <Stack.Item>
                                <RadNav
                                    Root={groups}
                                    Entities={Object.keys(currentUser.allowedEntities)}
                                    User={currentUser}
                                />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item grow>
                        <Stack>
                            <Stack.Item align="end">
                                <CommandBar
                                    items={[
                                        {
                                            key: "user",
                                            text: currentUser?.currentUser?.displayName,
                                            iconProps: { iconName: "Contact" }
                                        }
                                    ]}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <Switch>
                                    <Route exact path="/">
                                        <Welcome />
                                    </Route>
                                    <Route path="/_admin/ManageUsers">
                                        <ManageUsers />
                                    </Route>
                                    <Route path="/_admin/Role">
                                        <RoleOverview />
                                    </Route>
                                    <Route path="/Knz1Berichte">
                                        <Header Large>Kennzahl 1: Berichte</Header>

                                        <BerichteContainer Model={new BerichteRepository(EnumKennzahl.Knz1)} />
                                    </Route>
                                    <Route path="/Knz2Bereiche">
                                        <Header Large>Kennzahl 2: Bereiche</Header>
                                        <Knz2GK2022Container Model={new Knz2GK2022Repository()} />
                                    </Route>
                                    <Route path="/Knz2Abschnitte">
                                        <RadListContainer Repository={RadRepository.Knz2GK2022AbschnittModel} />
                                    </Route>
                                    <Route path="/Knz2Projekte">
                                        <Header Large>Kennzahl 2: Projekte</Header>
                                        <Knz2LSGK2022ProjekteContainer Model={new Knz2GK2022Repository()} />
                                    </Route>
                                    <Route path="/Knz2Berichte">
                                        <Header Large>Kennzahl 2 LS: Berichte</Header>
                                        <BerichteContainer Model={new BerichteRepository(EnumKennzahl.Knz2LS)} />
                                    </Route>
                                    <Route path="/Knz2LVBerichte">
                                        <Header Large>Kennzahl 2 LV: Berichte</Header>
                                        <BerichteContainer Model={new BerichteRepository(EnumKennzahl.Knz2LV)} />
                                    </Route>
                                    <Route path="/Knz2Altprojekte">
                                        <Header Large>Kennzahl 2: Altprojekte</Header>
                                        <Knz2AltprojekteContainer Model={new Knz2GK2022Repository()} />
                                    </Route>
                                    <Route path="/Knz2BereicheGK2018">
                                        <Header Large>Kennzahl 2: Bereiche (GK2018)</Header>
                                        <Knz2GK2018Container Model={new Knz2GK2018Repository()} />
                                    </Route>
                                    <Route path="/Knz2AbschnitteGK2018">
                                        <RadListContainer Repository={RadRepository.Knz2GK2018AbschnittModel} />
                                    </Route>
                                    <Route path="/Knz2ProjekteGK2018">
                                        <Header Large>Kennzahl 2: Projekte (GK2018)</Header>
                                        <Knz2LSGK2018ProjekteContainer Model={new Knz2GK2018Repository()} />
                                    </Route>
                                    <Route path="/Knz3Datenimporte">
                                        <Header Large>Kennzahl 3: Datenimporte</Header>
                                        <Knz3DatenImportContainer Model={new Knz3Repository()} />
                                    </Route>
                                    <Route path="/Knz3LSBerichte">
                                        <Header Large>Kennzahl 3 LS: Berichte</Header>
                                        <BerichteContainer Model={new BerichteRepository(EnumKennzahl.Knz3LS)} />
                                    </Route>
                                    <Route path="/Knz3LVBerichte">
                                        <Header Large>Kennzahl 3 LV: Berichte</Header>
                                        <BerichteContainer Model={new BerichteRepository(EnumKennzahl.Knz3LV)} />
                                    </Route>
                                    <Route path="/Knz3SPBerichte">
                                        <Header Large>Kennzahl 3 SP: Berichte</Header>
                                        <BerichteContainer Model={new BerichteRepository(EnumKennzahl.Knz3SP)} />
                                    </Route>
                                    <Route path="/Knz3ErlaubteAbk">
                                        <Header Large>Kennzahl 3: Erlaubte Abkürzungen</Header>
                                        <RadListContainer Repository={RadRepository.Knz3AbkModel} />
                                    </Route>
                                    <Route path="/Knz4LS">
                                        <Header Large>Kennzahl 4 LS: Objekte</Header>
                                        <Knz4LSContainer
                                            Model={new Knz4Repository()}
                                            BerichtModel={new BerichteRepository(EnumKennzahl.Knz4LS)}
                                        />
                                    </Route>
                                    <Route path="/Knz4LSFirmen">
                                        <RadListContainer Repository={RadRepository.Knz4LSIngBueroModel} />
                                    </Route>
                                    <Route path="/Knz4LSAbsender">
                                        <RadListContainer Repository={RadRepository.Knz4LSAbsenderModel} />
                                    </Route>
                                    <Route path="/Knz4LSBerichte">
                                        <Header Large>Kennzahl 4 LS: Berichte</Header>
                                        <BerichteContainer Model={new BerichteRepository(EnumKennzahl.Knz4LS)} />
                                    </Route>
                                    <Route path="/Knz4LSFusszeile">
                                        <Header Large>Kennzahl 4 LS: Fußzeile</Header>
                                        <Knz4LSSettings Repository={new Knz4Repository()} />
                                    </Route>
                                    <Route path="/Knz4LSDatenimporte">
                                        <Header Large>Kennzahl 4 LS: Datenimporte</Header>
                                        <DatenImportContainer
                                            Repository={LskRepository.Knz4LSDatenImport}
                                            ImportExcel={(f) => new Knz4LSClient().importExcelFile(f)}
                                            ValidateExcel={(f) => new Knz4LSClient().validateExcelFile(f)}
                                        />
                                    </Route>
                                    <Route path="/Knz4LV">
                                        <Header Large>Kennzahl 4 LV: Maßnahmen</Header>
                                        <Knz4LVContainer Model={new Knz4LVRepository()} />
                                    </Route>
                                    <Route path="/Knz4LVBerichte">
                                        <Header Large>Kennzahl 4 LV: Berichte</Header>
                                        <BerichteContainer Model={new BerichteRepository(EnumKennzahl.Knz4LV)} />
                                    </Route>
                                    <Route path="/Knz4LVDatenimporte">
                                        <Header Large>Kennzahl 4 LV: Datenimporte</Header>
                                        <DatenImportContainer
                                            Repository={LskRepository.Knz4LVDatenImport}
                                            ImportExcel={(f) => new Knz4LVClient().importExcelFile(f)}
                                            ValidateExcel={(f) => new Knz4LVClient().validateExcelFile(f)}
                                        />
                                    </Route>
                                    <Route path="/Tasks">
                                        <Header Large>Aufgaben</Header>
                                        <TaskListContainer Repository={new WorkflowRepository()} />
                                    </Route>
                                    <Route path="/Report">
                                        <Header Large>Gesamtprojektübersicht</Header>
                                        <FolderView
                                            Repository={RadRepository.Folder}
                                            ParentObjectId="Berichte"
                                            Disabled
                                        />
                                    </Route>
                                    <Route path="/_admin/Health">
                                        <Header Large>Health</Header>
                                        <Health />
                                    </Route>
                                </Switch>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </BrowserRouter>
        </DBFabric>
    );
}
