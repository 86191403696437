import { IComboBoxOption } from "office-ui-fabric-react/lib/ComboBox";

/**
 * Allows to use classes as parameters for functions/components
 */
export type ITypeWithArgs<T, A extends any[]> = new (...args: A) => T;
export type IType<T> = new () => T;

/**
 * Helper function that extract the property name of a class as string. As the type will be resolved during compile time
 * this will fail if the property can not be resolved.
 * Example:
 * nameof<Utils>("Sleep") => "Sleep"
 * @param name
 * @returns String
 */
export const nameof = <T>(name: Extract<keyof T, string>): string => name;

export const Bundeslaender = [
    "BB",
    "BE",
    "BW",
    "BY",
    "HB",
    "HE",
    "HH",
    "MV",
    "NI",
    "NRW",
    "RP",
    "SH",
    "SL",
    "SN",
    "ST",
    "TH"
];

export class Utils {
    public static Sleep(milliseconds: number): Promise<void> {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    }

    public static GroupBy(list: any[], keyGetter: (arg: any) => any): Map<any, any> {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    /**
     * Sort ascending, make sure null values are discriminated
     * @param a
     * @param b
     */
    public static SortAscending(a: string, b: string): number {
        if (a === null) {
            return 1;
        } else if (b === null) {
            return -1;
        } else if (a === b) {
            return 0;
        }
        return a < b ? -1 : 1;
    }

    public static EnumToArray(obj: any): string[] {
        return Object.values(obj);
    }

    public static ApplyMapping(input: string, mapping: object): string {
        return mapping[input];
    }

    public static EnumToComboBoxOption(obj: any, mapping: object): IComboBoxOption[] {
        let values: string[] = Object.values(obj);
        let output: IComboBoxOption[] = values.map((i) => ({ key: i, text: mapping[i] }));
        return output;
    }

    public static IsNumber(n: any): boolean {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    public static FormatDate(date: Date): string {
        if (!date) {
            return "";
        }
        // configure timestamp formatting
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "long",
            day: "numeric"
        };
        return date.toLocaleDateString("de-DE", options);
    }

    public static FormatDateTime(date: Date): string {
        if (!date) {
            return "";
        }
        // configure timestamp formatting
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "2-digit",
            second: "2-digit"
        };
        return date.toLocaleDateString("de-DE", options);
    }

    public static FormatDateTimeShort(date: Date): string {
        if (!date) {
            return "";
        }
        // configure timestamp formatting
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "2-digit",
            second: "2-digit"
        };
        return date.toLocaleDateString("de-DE", options);
    }

    public static IsNullOrEmpty(s: string): boolean {
        return !s;
    }

    /**
     * 12345.123132 => 12.345,123
     * @param km
     */
    public static FormatKilometer(km: number): string {
        if (km === null || km === undefined || isNaN(km)) {
            return "";
        }
        return km.toLocaleString("de-DE", { minimumFractionDigits: 3, maximumFractionDigits: 3 });
    }

    /**
     * 12345.12000 => 12345,12
     * @param km
     */
    public static FormatNumber(km: number): string {
        if (km === null || km === undefined || isNaN(km)) {
            return "";
        }

        return km.toLocaleString("de-DE");
    }

    /**
     * 1287,12 => 1.287,12 €
     * @param numValue
     */
    public static FormatNumberToEuro(numValue: number): string {
        if (numValue === null || numValue === undefined || isNaN(numValue)) {
            return "";
        }
        return numValue.toLocaleString("de-DE", { currency: "EUR", style: "currency" });
    }

    public static IncludesText(haystack: string, needle: string): boolean {
        if (!haystack || !needle) {
            return false;
        }

        return haystack.toLowerCase().includes(needle.toLowerCase());
    }

    public static KilometerDifference(km1: number, km2: number): number {
        if (km1 == null || km2 == null) {
            return null;
        }
        return Math.abs(km2 - km1);
    }

    public static JoinUrl(url1: string, url2: string): string {
        return url1.replace(/\/+$/, "") + "/" + url2.replace(/^\/+/, "");
    }

    public static MapMap<K, V>(input: Map<K, V>, filter: (value: [K, V]) => [K, V]): Map<K, V> {
        const output = new Map<K, V>();
        for (const value of input.entries()) {
            const r = filter(value);
            if (r) {
                output.set(r[0], r[1]);
            }
        }
        return output;
    }

    public static MapFilter<K, V>(input: Map<K, V>, filter: (key: K, value: V) => boolean): Map<K, V> {
        const output = new Map<K, V>();
        for (const value of input.entries()) {
            const r = filter(value[0], value[1]);
            if (r) {
                output.set(...value);
            }
        }
        return output;
    }

    public static MapFilterToArray<K, V>(input: Map<K, V>, filter: (key: K, value: V) => boolean): V[] {
        return [...this.MapFilter(input, filter).values()];
    }

    public static SelectMany<TIn, TOut>(input: TIn[], selectListFn: (t: TIn) => TOut[]): TOut[] {
        return input.reduce((out, inx) => {
            out.push(...selectListFn(inx));
            return out;
        }, new Array<TOut>());
    }
}
